<template>
  <div class="contact">
    <Banner
      :src="require('@/assets/images/banner03.jpg')"
      :active="false"
    ></Banner>
    <div class="main">
      <div class="main-title">
        <img src="~@/assets/images/contact.png" alt="" srcset="" />
      </div>
      <div class="section">
        <div class="section-title">科技输出与技术服务</div>
        <div class="group">
          <div class="item">
            <div class="img">
              <img src="~@/assets/images/logo01.png" alt="" srcset="" />
            </div>
            <div class="text">
              <p class="title">四川森淼泽成商务咨询有限公司</p>
              <p class="phone">联系电话：18610382076</p>
              <p class="email">商务邮箱：lichunhai@ihongsen.com</p>
              <p class="address">
                公司地址：成都市高新区剑南大道中段1098号1幢16层1601号
              </p>
            </div>
          </div>
          <!-- <div class="item">
            <div class="img">
              <img src="~@/assets/images/logo01.png" alt="" srcset="" />
            </div>
            <div class="text">
              <p class="title">
                四川森淼融联科技有限公司
              </p>
              <p class="phone">
                联系电话：18610382076
              </p>
              <p class="email">商务邮箱： lichunhai@ihongsen.com</p>
              <p class="address">
                公司地址：成都市高新区剑南大道中段1098号1幢16层1601号
              </p>
            </div>
          </div> -->
        </div>
      </div>
      <Divider class="divider"></Divider>
      <div class="section">
        <div class="section-title">网约车运力与汽车金融服务</div>
        <div class="group">
          <div class="item">
            <div class="img">
              <img src="~@/assets/images/logo05.png" alt="" srcset="" />
            </div>
            <div class="text">
              <p class="title">成都库能捷乘科技有限公司</p>
              <p class="phone">联系电话：400-876-6605</p>
              <p class="address">
               公司地址：四川省成都市高新区剑南大道中段1098号1幢16层1603号
              </p>
            </div>
          </div>
          <div class="item">
            <div class="img">
              <img src="~@/assets/images/logo02.png" alt="" srcset="" />
            </div>
            <div class="text">
              <p class="title">四川金凯龙汽车租赁有限公司</p>
              <p class="phone">联系电话：400-000-7139</p>
              <p class="address">公司地址：四川省成都市武侯区佳灵路60号</p>
            </div>
          </div>
          <div class="item">
            <div class="img">
              <img src="~@/assets/images/logo03.png" alt="" srcset="" />
            </div>
            <div class="text">
              <p class="title">湖南瑞僖融资租赁有限公司</p>
              <div class="re">
                <p class="phone">联系电话：0731-85576093</p>
                <p class="email">商务邮箱：hh111558@126.com</p>
              </div>
              <p class="address">
                公司地址：湖南省长沙市岳麓区南二环777号瑞僖汽车展厅
              </p>
            </div>
          </div>
        </div>
      </div>
      <Divider class="divider"></Divider>
      <div class="section">
        <div class="section-title">网约车平台服务</div>
        <div class="group">
          <div class="item">
            <div class="img">
              <img src="~@/assets/images/logo04.png" alt="" srcset="" />
            </div>
            <div class="text">
              <p class="title">成都僖行天下科技有限公司</p>
              <div class="re">

              <p class="phone">联系电话：18081047178</p>
              <p class="email">商务邮箱： huyong@91xixing.com</p>
              </div>
              <p class="address">
                公司地址：成都市高新区剑南大道中段1098号1幢16层1601号
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from "./Banner";
import Divider from "./Divider";
export default {
  components: {
    Banner,
    Divider,
  },
};
</script>

<style lang="less" scoped>
.main {
  max-width: 1280px;
  margin: 0 auto;
  padding-bottom: 76px;
  color: #121212;
  .main-title {
    margin-top: 40px;
    text-align: center;
  }
  .divider {
    margin-top: 40px;
  }
  .section {
    .section-title {
      text-align: center;
      margin: 30px 0;
      font-size: 24px;
      font-weight: bold;
    }
    .group {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .item {
        width: 50%;
        margin-left: 14px;
        margin-bottom:20px;
        display: flex;
        align-items: center;
        .img {
          padding: 10px;
          max-height: fit-content;
          margin-right: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          box-shadow: 1px 1px 16px 0px rgba(28, 116, 255, 0.1);
          border-radius: 12px;
        }
        .text {
          .re{
            width: 100%;
            display:flex;
            justify-content: space-between;
            &>p{
              margin-right:40px;
            }
          }
          p {
            margin: 0;
            color: #121212;
          }
          .title {
            font-size: 16px;
            font-weight: bold;
          }
          .phone,
          .email,
          .address {
            margin-top: 0.5em;
            font-size: 14px;
          }
        }
      }
    }
  }
}
</style>
